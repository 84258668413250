import { ChangeEvent, useState } from 'react';
import App from './App';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ImageIcon from '@mui/icons-material/Image';
import PsychologyIcon from '@mui/icons-material/Psychology';
import Alert from '@mui/material/Alert';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { IResultUploadFile } from './models/IResultUploadFile';
import { IComponentProps } from './models/IComponentProps';


interface IModelOutputAi {
    ImagePath: string;
    Label: string;
    PredictedLabel: string;
    Data: Array<IPredictedList>;
}

interface IPredictedList {
  TagName: string;
  Prediction: string;
}

function FileUploadSingle(props:IComponentProps) {
  const [fileUpload, setFileUpload] = useState<File>();
  const [infoFileStatus, setInfoFileStatus] = useState('');
  
  //const [infoFileResult, setInfoFileResult] = useState<IResultUploadFile>();
  const [imgPath, setImgPath] = useState('');
  const [modelOutputAi, setModelOutputAi] = useState<IModelOutputAi>();



  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFileUpload(e.target.files[0]);
      setModelOutputAi({} as IModelOutputAi);
      handleUploadClick(e.target.files[0]);
      
    }
  };

  const handleUploadClick = (file: File) => {
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append('FormFile', file);
    formData.append('Note', '');
    
    // 👇 Uploading the file using the fetch API to the server
    fetch(props.pathApi + 'Files/upload', {
      method: 'POST',
      body: formData,
      // 👇 Set headers manually for single file upload
      headers: {
        //'content-type': file.type,
        'content-length': `${file.size}`, // 👈 Headers need to be a string
      },
      
    })
      .then((res) => res.json())
      .then((data: IResultUploadFile) => {
            console.log(data);
            setInfoFileStatus(data.status);
            //setInfoFileResult(data);
            props.onChange(data.guidFile);
            fetch(props.pathApi + 'Files/create-spectrogram?id=' + data.guidFile, {
              method: 'GET',
            })
            .then((res) => res.json())
      })
      .catch((err) => console.error(err));
  };

  async function fetchAi() {

    const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  };

    const res = await fetch(props.pathApi + "ImageClassification/csi-test?fileName=" + props.fileUploadGuid, requestOptions);
    res
      .json()
      .then(
        (res:IModelOutputAi) => {
          setModelOutputAi(res);
          console.log(res);
        })
      .catch(
        err => {
          console.log("error");
          }
      );
  };

  async function fetchSoundNormalizer() {

    const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  };

    const res = await fetch(props.pathApi + "Files/sound-normalizer?id=" + props.fileUploadGuid, requestOptions);
    res
      .json()
      .then((data: IResultUploadFile) => {
        console.log(data);
        setInfoFileStatus(data.status);
        //setInfoFileResult(data);
        props.onChange(data.guidFile);
        fetch(props.pathApi + 'Files/create-spectrogram?id=' + data.guidFile, {
          method: 'GET',
        })
        .then((res) => res.json())
      })
      .catch(
        err => {
          console.log("error");
          }
      );
  };

  const handleImageClick = () => {
    setImgPath(props.pathApi + 'Files/image?id=' + props.fileUploadGuid)
  }
  const handleAiAnalysisClick = () => {
    fetchAi();
  }

  const handleSoundNormalizerClick = () => {
    fetchSoundNormalizer();
  }


  return (
    <div>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          Upload souboru. Pouze zvuk typu wav soubor.
        </Grid>

        <Grid item xs={3}>
          <Button variant="contained" component="label">
          <FileUploadIcon /> Upload
          <input hidden type="file" onChange={handleFileChange} />
          {/* <input hidden accept="image/*" multiple type="file" /> */}
          </Button>

        </Grid>
        <Grid item xs={3}>
          <Button onClick={handleSoundNormalizerClick} variant="contained"><VolumeUpIcon/> Zvuk - normalizace</Button>
        </Grid>

        <Grid item xs={3}>
          <Button onClick={handleImageClick} variant="contained"> <ImageIcon/> Spectrogram</Button>
        </Grid>
        <Grid item xs={3}>
          <Button onClick={handleAiAnalysisClick} variant="contained"><PsychologyIcon/> AI analysis</Button>
        </Grid>

        <Grid item xs={12}>
          {fileUpload && `${fileUpload.name} - ${fileUpload.type}`} {infoFileStatus}
        </Grid>
        <Grid item xs={12}>
          {infoFileStatus} {props.fileUploadGuid}
        </Grid>

        <Grid item xs={12}>
          {props.fileUploadGuid}
        </Grid>

      </Grid>

      {modelOutputAi?.PredictedLabel !== undefined &&
        <Alert severity="success">Predicted <b>{modelOutputAi?.Label}: {modelOutputAi?.PredictedLabel}</b></Alert>
      }
      {modelOutputAi?.PredictedLabel !== undefined &&
        <table> {modelOutputAi?.Data?.map((prediction) => <tr><td>{prediction.TagName}</td><td>{prediction.Prediction}</td></tr>)}</table>
      }

      {/* <h1>Predicted {modelOutputAi?.PredictedLabel}</h1> */}
      <img src={imgPath} alt='Spectogram'></img>
    </div>
  );
}

export default FileUploadSingle;
