import { ChangeEvent, useState } from 'react';
import App from './App';
import AudioAnalyser from 'react-audio-analyser'
import Button from '@mui/material/Button';
import { IResultUploadFile } from './models/IResultUploadFile';
import { IComponentProps } from './models/IComponentProps';
import Grid from '@mui/material/Grid';
  
  function RecordSound2(props:IComponentProps) {
    const [file, setFile] = useState<File>();

    const [status, setStatus] = useState('');
    const [audioSrc, setAudioSrc] = useState('');
  
    const handleUploadClick = (e: Blob) => {
  
  
      const formData = new FormData();
      //const audioBlob = new Blob([audioProps.audioSrc as Blob])
  
      formData.append('FormFile', e);
      formData.append('Note', '');
      
      // 👇 Uploading the file using the fetch API to the server
      fetch(props.pathApi + 'Files/upload', {
        method: 'POST',
        body: formData,
        // 👇 Set headers manually for single file upload
        headers: {
          //'content-type': file.type,
          'content-length': `${e.size}`, // 👈 Headers need to be a string
        },
        
      })
        .then((res) => res.json())
        .then((data: IResultUploadFile) => {

            props.onChange(data.guidFile);

            fetch(props.pathApi + 'Files/create-spectrogram?id=' + data.guidFile, {
                method: 'GET',
              })
              .then((res) => res.json())
  
        })
        .catch((err) => console.error(err));
    };
  
    const controlAudio = (status: string) => {
        setStatus(status);
    }

    //const {status, audioSrc, audioType} = this.state;
    const audioProps = {
            audioType: 'audio/wav',
            // audioOptions: {sampleRate: 30000}, // 设置输出音频采样率
            status,
            audioSrc,
            timeslice: 1000, // timeslice（https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder/start#Parameters）
            startCallback: (e:any) => {
                console.log("succ start", e)
            },
            pauseCallback: (e:any) => {
                console.log("succ pause", e)
            },
            stopCallback: (e:any) => {
                setAudioSrc(window.URL.createObjectURL(e));

                handleUploadClick(e);
                /*setState({
                    audioSrc: window.URL.createObjectURL(e)
                })*/
                console.log("succ stop", e)
            },
            onRecordCallback: (e:any) => {
                console.log("recording", e)
            },
            errorCallback: (err:any) => {
                console.log("error", err)
            }
        }


        return (
            <div>
                <AudioAnalyser {...audioProps}>
                    <div className="btn-box">
                        {status !== "recording" &&
                        <i className="iconfont icon-start" title="开始"
                           onClick={() => controlAudio("recording")}></i>}
                        {status === "recording" &&
                        <i className="iconfont icon-pause" title="暂停"
                           onClick={() => controlAudio("paused")}></i>}
                        <i className="iconfont icon-stop" title="停止"
                           onClick={() => controlAudio("inactive")}></i>
                    </div>
                </AudioAnalyser>

                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Button variant="outlined" onClick={() => controlAudio("recording")}>recording</Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="outlined" onClick={() => controlAudio("inactive")}>stop + upload</Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="outlined" onClick={() => controlAudio("paused")}>paused</Button>
                    </Grid>

                {/* <Button onClick={handleUploadClick} variant="contained">Upload record</Button> */}

                    <Grid item xs={12}>
                    {props.fileUploadGuid}
                    </Grid>
                </Grid>
            </div>
        );
  }
  
  export default RecordSound2;
  